import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
// service
import { StorageService } from '../services/storage.service';
// Dialog Box
import { UtilService } from '../services/util.service';
import { ROUTES_CONFIG } from '../routes';
import { ConfirmationDialogComponent } from '../components/confirmation-dialog/confirmation-dialog.component';
import { HeaderService } from 'src/app/modules/layout/header/header.service';
import { apiMessage } from '../validation/apiErrorMessage';
import { saveAs } from 'file-saver';
import { CONSTANT } from '../constant';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

  private PORTAL_API_URL: string;
  private LOCATION_API_URL: string;
  private REQUEST: HttpRequest<any>;
  private ingnoreFor201Handle = [ROUTES_CONFIG.SEND_OTP];
  dialogRef: any;
  constructor(
    private utilService: UtilService,
    private storageService: StorageService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    public dialog: MatDialog,
    private headerService: HeaderService
  ) {
    this.PORTAL_API_URL = ROUTES_CONFIG.API_URL;
    this.LOCATION_API_URL = ROUTES_CONFIG.LOCATION_API_URL;
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    /**
     * Set Headers
     */
    if (!request.headers.has('Content-Type')) {
      request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
    }
    /**
     * set api url
     */

    this.REQUEST = request;
    const requestUrl = request.url;


    let API_URL = this.PORTAL_API_URL;

    // set auth token
    const authtoken = this.storageService.get(environment.auth_token_key);
    if (authtoken && authtoken.trim().length > 0 && !request.headers.has('Authorization')) {
      request = request.clone({ headers: request.headers.set('Authorization', `${CONSTANT.TOKEN_TYPE} ${authtoken}`) });
    }

    request = request.clone({
      url: `${API_URL}${request.url}`
    });

    if (requestUrl.indexOf('locations/') !== -1) {
      API_URL = this.LOCATION_API_URL;
    }
    // if (requestUrl.indexOf('products/exceltemplate') !== -1) {
    //   request = request.clone({
    //     responseType: 'blob'
    //   });
    // }


    // ---------------------
    return next.handle(request).pipe(
      tap(
        event => {
          const tempUrl = request.url.replace(API_URL, '');
          if (event instanceof HttpResponse && this.ingnoreFor201Handle.indexOf(tempUrl) < 0) {
            this.handleExportFiles(event, tempUrl);
            if (event.status === 201) {
              if (this.dialogRef === '' || this.dialogRef === undefined) {
                let dialogData;
                if (event.body.message !== undefined) {
                  const message = event.body.message;
                  dialogData = {
                    heading: message.title,
                    text: message.body,
                    note: '',
                    type: 'Handle401',
                  };
                }

                if (event.body.flag !== undefined && event.body.flag === 'ORG_DEACTIVE') {
                  this.handleOrganizationDeactivation(dialogData);
                }
                if (event.body.flag !== undefined && event.body.flag === 'USER_DEACTIVE') {
                  this.handle401(dialogData);
                }

                if (event.body.status === 'Already connected') {
                  dialogData = {
                    heading: 'Already connected',
                    text: `You have been already connected with the same retailer organization.`,
                    note: '',
                    type: 'ConnectionError',
                  };
                  this.alreadyConnected(dialogData);
                }
              }
            }

          }
        },
        error => {
          switch (error.status) {
            case 401:
              if (this.dialogRef === '' || this.dialogRef === undefined) {
                const dialogData = {
                  heading: 'Failure',
                  text: `Your session has been expired, please login again to continue.`,
                  note: '',
                  type: 'Handle401',
                };
                this.handle401(dialogData);
              }
              break;
            case 309:
              if (this.dialogRef === '' || this.dialogRef === undefined) {
                const dialogData = {
                  heading: 'Access Denied',
                  text: error.error.message || apiMessage.access_denied,
                  note: '',
                  type: 'Handle401',
                };
                this.accessDenied(dialogData);
              }
              break;
            case 404:
              this.utilService.openSnackBar({
                message: `${request.url} is not found`,
                status: 2
              });
              break;

            default:
              const errorMessage = this.utilService.httpStatusErrorMessage(error.status);
              const messageData = {
                message: errorMessage,
                status: 2
              };
              this.utilService.openSnackBar(messageData);
              break;
          }
          if (document.getElementById('load-overlay') !== undefined && document.getElementById('load-overlay') !== null) {
            document.getElementById('load-overlay').classList.add('hide-loader');
            document.getElementById('load-overlay').classList.remove('show-loader');
          }
        }
      )
    );
  }

  handleExportFiles(event, url) {
    if (!this.checkUrl(url)) {
      return;
    }

    const contentType = event.headers.get('Content-Type') || '';
    if (contentType.includes('application/json')) {
      const recordsHeader = event.headers.get('records');
      if (recordsHeader?.includes('no_records')) {
        this.utilService.openSnackBar({ message: apiMessage.email_export[`NO_RECORDS`], status: 2 });
        return;
      }
      if (event.body?.status?.toLowerCase() === 'failure') {
        this.utilService.openSnackBar({ message: apiMessage.email_export[event.body?.flag], status: 2 });
        return;
      }
      this.utilService.openSnackBar({ message: apiMessage.email_export[`EMAIL_XLS`] });
      return;
    }
    const body = { ...this.REQUEST.body };
    const fileName = body?.fileName || this.REQUEST.params.get('fileName') || 'OX_EXCEL';
    const blob = new Blob([event.body], { type: 'application/vnd.ms.excel' });
    const file = new File([blob], `${fileName}.xlsx`, { type: 'application/vnd.ms.excel' });
    saveAs(file);
  }

  private checkUrl(url: string): boolean {
    return (
      (url.includes('xls') && (url.includes('inventory') || url.includes('products/fetch') || url.includes('orders') || url.includes('reports'))) ||
      (url.includes('detailed-preview') && url.includes('dispatch')) ||
      (url.includes('downloadInvalidRecords') && url.includes('batches')) ||
      url.includes('order-utilities/orders-report') ||
      url.includes('retailers/export')
    );
  };

  handleOrganizationDeactivation(dialogData) {
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: dialogData
    });
    this.dialogRef.afterClosed().subscribe(result => {
      this.dialogRef = '';
      this.router.navigate(['/dashboard'], { state: { data: { type: 'ORG_DEACTIVE' } } });
    });
  }

  handle401(dialogData) {
    if (this.REQUEST.params.get('via') === 'app') {
      return;
    }
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: dialogData
    });
    this.dialogRef.afterClosed().subscribe(result => {
      this.headerService.logout().subscribe((res: any) => { });
      this.authService.updateIsSignupProcessComplete.next(false);
      this.authService.updateIsLoginProcessComplete.next(false);
      this.storageService.clearSessionKeys();
      this.dialogRef = '';
      this.router.navigate(['/login']);
    });
  }

  alreadyConnected(dialogData) {
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: dialogData
    });
    this.dialogRef.afterClosed().subscribe(result => {
      this.dialogRef = '';
    });
  }

  accessDenied(dialogData) {
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: dialogData
    });
    this.dialogRef.afterClosed().subscribe(result => {
      this.dialogRef = '';
      // window.location.reload();
      this.router.navigate(['/dashboard']);
    });
  }


}
