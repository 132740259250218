import { ICreateDispatch } from "src/app/modules/pages/challans/interfaces";

class CreateDispatch {
  static readonly type = '[Dispatch Shared] Create Distpatch';
  constructor(public payload: ICreateDispatch) { }
}

class FetchExistingDispatch {
  static readonly type = '[Dispatch Shared] Fetch Existing Distpatch';
  constructor(public params: any) { }
}

class ResetDispatchShared {
  static readonly type = '[Dispatch Shared] Reset Dispatch Shared';
  constructor(public key: string = null) { }
}

export {
  CreateDispatch,
  FetchExistingDispatch,
  ResetDispatchShared
}
