import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { ChallansService } from 'src/app/modules/pages/challans/services/challans.service';
import { CreateDispatch, FetchExistingDispatch, ResetDispatchShared } from './actions';
import * as immutable from 'object-path-immutable';
import { ApiService } from '../../services/api';
import { ROUTES_CONFIG } from '../../routes';

const initialState: any = {
  dispatchId: null,

  error: false,
  errorFlag: ''
}
@State<any>({
  name: 'dispatchShared',
  defaults: {
    ...initialState
  }
})

@Injectable()
export class DispatchSharedState {

  constructor(
    private challan: ChallansService,
    private _api: ApiService
  ) { }

  @Action(CreateDispatch)
  createDispatch(ctx: any, { payload }: CreateDispatch) {
    return this.challan.createDispatch(payload).pipe(
      tap(res => {
        let stateData: any = {
          dispatchId: res.dispatch_id || null
        }
        if (res?.status?.toLowerCase() === 'failure') {
          stateData = {
            ...stateData,
            error: true,
            errorFlag: res.flag || ''
          }
        }
        ctx.patchState({
          ...stateData
        });
      })
    );
  }

  @Action(FetchExistingDispatch)
  fetchExistingDispatch(ctx: StateContext<any>, { params }: FetchExistingDispatch) {
    return this._api.put(ROUTES_CONFIG.DISPATCH_EXISTING, params).pipe(
      tap(res => {
        ctx.patchState({
          dispatchId: res.dispatch_id || null,
        });
      })
    );
  }

  @Action(ResetDispatchShared, { cancelUncompleted: true })
  resetDispatch({ setState, getState }: StateContext<any>, { key }: ResetDispatchShared): any {
    if (!key) {
      setState({
        ...initialState
      });
      return;
    }

    const state = immutable.set(
      getState(),
      `${key}`,
      immutable.get(initialState, `${key}`)
    );

    setState(state);

    // setState(patch({
    //   [key]: initialState[key]
    // }))
  }
}
